<template>
  <div style="width:100%;" class="ma-0 pa-4">
    <v-row class="pa-0">
      <v-col cols="12" md="6">
        <v-expansion-panels v-model="settings_panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-if="settings_panel === 0">
                <v-card-title class="ma-0 pa-0">Report Settings</v-card-title>
              </template>
              <template v-else>
                <v-card-title class="ma-0 pa-0"
                  >Report Settings
                  <v-spacer></v-spacer>
                  <span style="font-size:.75em;font-weight:normal;">
                    {{ dateRangeText }} {{ report_settings.timezone.name }}
                  </span>
                </v-card-title>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Date range"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="report_settings.dates"
                  range
                ></v-date-picker>
              </v-menu>
              <v-row>
                <v-col cols="4" class="pb-0">
                  <v-select
                    v-model="report_settings.interval"
                    label="Interval"
                    :items="intervals"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-select
                    v-model="report_settings.timezone"
                    label="Timezone"
                    :items="timezones"
                    item-text="name"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="4" class="pb-0 pt-5">
                  <v-btn
                    @click="
                      do_search();
                      settings_panel = null;
                      filter_panel = null;
                    "
                    color="success"
                    >Generate</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6">
        <v-expansion-panels v-model="filter_panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-title class="ma-0 pa-0"
                >Filters</v-card-title
              ></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-container fluid class="pa-0 ma-0 pl-4">
                <v-row>
                  <v-col cols="6" class="mt-0 pt-0">
                    <GroupFilter
                      class="mt-2"
                      @filter-group-rules="filter_group_rules"
                      :default="true"
                      :flat="true"
                    ></GroupFilter>
                  </v-col>
                  <v-col cols="3" class="mt-0 pt-0 pl-3">
                    <v-checkbox
                      dense
                      hide-details
                      label="AIA"
                      v-model="filters.include.aia"
                    ></v-checkbox>
                    <v-checkbox
                      dense
                      hide-details
                      label="SIA"
                      v-model="filters.include.sia"
                    ></v-checkbox>
                    <v-checkbox
                      dense
                      hide-details
                      label="CRL"
                      v-model="filters.include.crl"
                    ></v-checkbox>
                    <v-checkbox
                      dense
                      hide-details
                      label="OCSP"
                      v-model="filters.include.ocsp"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3" class="mt-0 pt-0 pl-3">
                    <v-checkbox
                      dense
                      hide-details
                      label="Public Data"
                      v-model="filters.include.public"
                    ></v-checkbox>
                    <v-checkbox
                      dense
                      hide-details
                      label="Private Data"
                      v-model="filters.include.private"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card v-if="results.length > 0" class="mt-2">
          <v-card-text>
            <GroupAvailabilityPies :group_downtime="group_downtime" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <template v-if="private_loading === false">
          <v-btn
            v-if="filters.include.private"
            text
            disabled
            :loading="false"
            color="green"
            ><v-icon>mdi-check-circle</v-icon> Private Data</v-btn
          >
        </template>
        <template v-else-if="private_loading">
          <v-btn
            v-if="filters.include.private"
            text
            disabled
            :loading="true"
          ></v-btn>
        </template>
        <template v-else></template>

        <template v-if="public_loading === false">
          <v-btn
            v-if="filters.include.public"
            text
            disabled
            :loading="false"
            color="green"
            ><v-icon>mdi-check-circle</v-icon> Public Data</v-btn
          >
        </template>
        <template v-else-if="public_loading">
          <v-btn
            v-if="filters.include.public"
            text
            disabled
            :loading="true"
          ></v-btn>
        </template>
        <template v-else></template>

        <ReportingAvailabilityBarChart
          :settings="group_downtime"
          :headers="time_periods_headers"
          v-if="group_downtime.length > 0"
          class="mt-2"
        ></ReportingAvailabilityBarChart>
      </v-col>
    </v-row>
    <v-row v-if="groups_and_settings">
      <v-col cols="12">
        <div class="" id="slider-color" style="margin-top:40px"></div>

        <v-data-table
          :headers="time_periods_headers"
          :items="issuer_data"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="canonical_sha1"
          :search="search_issuers"
          show-expand
        >
          <template
            v-for="x in time_periods_headers"
            v-slot:[`item.${x.value}`]="{ item }"
          >
            <template v-if="x.value === 'name.friendly_name'">
              <Fqdn :value="item.name" :key="x.id" />
            </template>
            <template v-else>
              <v-text-field
                :class="get_classes(item.data[x.text])"
                outlined
                dense
                hide-details
                readonly
                :value="item.data[x.text]"
                :key="x.id"
              ></v-text-field>
            </template>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td colspan="2" class="pa-0 ma-0" style="background-color:#f5f5f5;">
              <table style="height:100%;border-spacing:0;">
                <tr
                  v-for="uri in item.uri_data"
                  :key="uri.id"
                  style="background-color:transparent !important;"
                >
                  <td style="border-bottom:1px solid #ddd;">
                    <v-text-field
                      readonly
                      dense
                      style="height:24px;font-size:12px;width:100%;"
                      ref="textToCopy"
                      class="ma-0 pa-0 flex-grow-1"
                      :value="uri.info.uri"
                    >
                      <template
                        v-slot:prepend
                        v-if="uri.info.is_private"
                        class="pt-3"
                      >
                        <v-chip x-small color="secondary">PRIVATE</v-chip>
                      </template>
                      <template v-slot:append class="ma-0 pa-0">
                        <v-btn
                          class="ma-0 mr-0 pa-2 ml-1 mb-1"
                          color="primary"
                          label
                          x-small
                          text
                          outlined
                          :href="`/url/${uri.info.id}/`"
                          target="_blank"
                        >
                          <v-icon small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                </tr>
              </table>
            </td>
            <td
              :colspan="time_periods_headers.length - 1"
              class="pa-0 ma-0"
              style="background-color:#f5f5f5;"
            >
              <table style="border-spacing:0;">
                <tr
                  v-for="uri in item.uri_data"
                  :key="uri.id"
                  style="background-color:transparent !important;"
                >
                  <td
                    v-for="t in time_periods_headers"
                    :key="t.id"
                    style="border-bottom:1px solid #ddd;"
                  >
                    <v-text-field
                      v-if="uri.data[t.text]"
                      @click.stop="
                        skittle_data = uri.data[t.text];
                        skittle_date = t.text;
                        skittle_uri = uri.info;
                        skittle_dialog = true;
                      "
                      style="transform: scale(0.875);"
                      :class="get_classes(uri.data[t.text].percent)"
                      outlined
                      dense
                      hide-details
                      readonly
                      :value="uri.data[t.text].percent"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </td>
          </template>

          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "[[ search_issuers ]]" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card v-if="results.length > 0" class="mt-2">
          <v-card-text>
            <ReportingAvailabilityUriTable
              :data="results"
              :uris="uris"
              :groups="group_uris"
              :included_uris="included_uris"
            ></ReportingAvailabilityUriTable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import UserService from "../../store/UserService";
import GroupFilter from "../../components/groups/Filter";
import ReportingAvailabilityBarChart from "../../components/reporting/availability/BarChart";
import Fqdn from "../../components/Fqdn";
import GroupAvailabilityPies from "../../components/reporting/availability/Pies";
import ReportingAvailabilityUriTable from "../../components/reporting/availability/UriTable";

export default {
  name: "AvailabilityReport",
  components: {
    ReportingAvailabilityUriTable,
    GroupAvailabilityPies,
    Fqdn,
    ReportingAvailabilityBarChart,
    GroupFilter
  },
  data: function() {
    return {
      filter_panel: 0,
      settings_panel: 0,
      skittle_dialog: false,
      skittle_data: null,
      skittle_date: null,
      skittle_uri: null,
      slider: null,
      values: [90, 96.5, 99, 99.9, 99.99],
      search_issuers: null,
      expanded: [],
      singleExpand: false,
      menu1: false,
      report_settings: {
        dates: [
          moment()
            .subtract(3, "days")
            .format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD")
        ],
        interval: "day",
        timezone: {
          name: "US/Eastern",
          offset: "GMT-05:00",
          value: -5,
          description: "Eastern Time (US & Canada)"
        }
      },
      filters: {
        filter_group_rules: { ca: [], path: [] },
        include: {
          aia: true,
          sia: true,
          ocsp: true,
          crl: true,
          ldap: true,
          http: true,
          public: true,
          private: true
        }
      },
      intervals: ["day", "week", "month"],
      timezones: [
        {
          name: "America/Los_Angeles",
          offset: "GMT-08:00",
          value: -8,
          description: "Pacific Time (US & Canada)"
        },
        {
          name: "US/Eastern",
          offset: "GMT-05:00",
          value: -5,
          description: "Eastern Time (US & Canada)"
        }
      ],
      private_results: [],
      public_results: [],
      private_loading: null,
      public_loading: null,
      uris: [],
      issuer_uris: {},
      time_periods: null
    };
  },
  computed: {
    monitored_groups: function() {
      return this.$store.getters.monitored_groups;
    },
    group_settings: function() {
      return this.$store.getters.monitoring_groups;
    },
    groups_and_settings: function() {
      return this.private_results.length > 0;
      //return this.monitored_groups.length > 0 && this.group_settings.length > 0;
    },
    uri_ids: function() {
      let result = {};

      for (let x in this.uris) {
        result[this.uris[x].sha1] = this.uris[x].id;
      }
      return result;
    },
    uri_dict: function() {
      let result = {};

      for (let x in this.uris) {
        result[this.uris[x].id] = this.uris[x];
      }
      return result;
    },
    results: function() {
      if (this.filters.include["public"] && this.filters.include["private"]) {
        return this.public_results.concat(this.private_results);
      } else if (this.filters.include["public"]) {
        return this.public_results;
      } else if (this.filters.include["private"]) {
        return this.private_results;
      } else {
        return [];
      }
    },
    dateRangeText() {
      return this.report_settings.dates.join(" ~ ");
    },
    computed_groups: function() {
      // all groups and their settings if they exist
      return this.monitored_groups();
    },
    /*
filtered_uris: function(){
    return this.uris.filter(function (value, index, arr) {
        return value.id !== 0;
    });
},
*/
    group_uris: function() {
      if (this.uris.length === 0) {
        return [];
      }
      let self = this;
      let result = [];
      let issuers = {};
      for (let x in this.monitored_groups) {
        if (this.monitored_groups[x].group) {
          let group = this.monitored_groups[x];
          let rules = [];
          if (group.group.group_type === 1) {
            // CA rule group: add uris for the CAs
            for (let key in group.group.ca_rules) {
              let rule = group.group.ca_rules[key];
              rules.push({
                id: rule.id,
                key: rule.public_key_id,
                name: rule.fqdn.friendly_name,
                uris: rule.issued_uris
              });
              if (!issuers[rule.fqdn.canonical_sha1]) {
                issuers[rule.fqdn.canonical_sha1] = {
                  name: rule.fqdn,
                  friendly_name: rule.fqdn.friendly_name,
                  fqdns: {}
                };
                issuers[rule.fqdn.canonical_sha1].fqdns[rule.fqdn.id] = {
                  friendly_name: rule.fqdn.friendly_name,
                  public_keys: {}
                };
                issuers[rule.fqdn.canonical_sha1].fqdns[
                  rule.fqdn.id
                ].public_keys[rule.public_key_id] = rule.issued_uris;
              } else if (
                !issuers[rule.fqdn.canonical_sha1].fqdns[rule.fqdn.id]
              ) {
                // new version for existing canonical name
                issuers[rule.fqdn.canonical_sha1].fqdns[rule.fqdn.id] = {
                  friendly_name: rule.fqdn.friendly_name,
                  public_keys: {}
                };
                issuers[rule.fqdn.canonical_sha1].fqdns[
                  rule.fqdn.id
                ].public_keys[rule.public_key_id] = rule.issued_uris;
              } else if (
                !issuers[rule.fqdn.canonical_sha1].fqdns[rule.fqdn.id]
                  .public_keys[rule.public_key_id]
              ) {
                // new key for this name
                issuers[rule.fqdn.canonical_sha1].fqdns[
                  rule.fqdn.id
                ].public_keys[rule.public_key_id] = rule.issued_uris;
              } else {
                // already exists
              }
            }
          } else {
            // Path group: add uris for every ca in every policy path
            for (let key in group.group.path_rules) {
              let rule = group.group.path_rules[key];
              for (let pp in rule.policy_paths) {
                let policy_path = rule.policy_paths[pp];

                for (let c in policy_path.certificates) {
                  let ca = policy_path.certificates[c];
                  let issued_uris = ca.issued_uris;
                  if (!issued_uris) {
                    issued_uris = {
                      aia_uris: [],
                      sia_uris: [],
                      crl_uris: [],
                      ocsp_uris: []
                    };
                  }
                  rules.push({
                    id: rule.id,
                    key: ca.public_key,
                    name: ca.subject_fqdn.friendly_name,
                    uris: issued_uris
                  });
                  // issuers[ca.subject_fqdn.friendly_name][ca.public_key] = issued_uris;
                  if (!issuers[ca.subject_fqdn.canonical_sha1]) {
                    // new canonical name
                    issuers[ca.subject_fqdn.canonical_sha1] = {
                      name: ca.subject_fqdn,
                      friendly_name: ca.subject_fqdn.friendly_name,
                      fqdns: {}
                    };
                    issuers[ca.subject_fqdn.canonical_sha1].fqdns[
                      ca.subject_fqdn.id
                    ] = {
                      friendly_name: ca.subject_fqdn.friendly_name,
                      public_keys: {}
                    };
                    issuers[ca.subject_fqdn.canonical_sha1].fqdns[
                      ca.subject_fqdn.id
                    ].public_keys[ca.public_key] = issued_uris;
                  } else if (
                    !issuers[ca.subject_fqdn.canonical_sha1].fqdns[
                      ca.subject_fqdn.id
                    ]
                  ) {
                    // new version for existing canonical name
                    issuers[ca.subject_fqdn.canonical_sha1].fqdns[
                      ca.subject_fqdn.id
                    ] = {
                      friendly_name: ca.subject_fqdn.friendly_name,
                      public_keys: {}
                    };
                    issuers[ca.subject_fqdn.canonical_sha1].fqdns[
                      ca.subject_fqdn.id
                    ].public_keys[ca.public_key] = issued_uris;
                    //console.log(issuers[ca.subject_fqdn.canonical_sha1]);
                  } else if (
                    !issuers[ca.subject_fqdn.canonical_sha1].fqdns[
                      ca.subject_fqdn.id
                    ].public_keys[ca.public_key]
                  ) {
                    // new key for this name
                    issuers[ca.subject_fqdn.canonical_sha1].fqdns[
                      ca.subject_fqdn.id
                    ].public_keys[ca.public_key] = issued_uris;
                  } else {
                    // already exists
                  }
                }
              }
            }
          }

          result.push({
            id: group.group.id,
            name: group.group.name,
            color: group.settings.color,
            rules: rules,
            group_type: group.group.group_type
          });
        }
      }
      self.issuer_uris = issuers;
      return result;
    },
    issuer_data: function() {
      let self = this;
      if (this.issuer_uris && this.included_uris) {
        let periods = new Set();
        let result = [];
        for (const x in this.issuer_uris) {
          let issuer = this.issuer_uris[x];
          let uris = [];
          let xuris = [];
          for (const y in issuer.fqdns) {
            let dn = issuer.fqdns[y];
            for (const z in dn.public_keys) {
              let pk = dn.public_keys[z];
              xuris = xuris
                .concat(pk.aia_uris)
                .concat(pk.sia_uris)
                .concat(pk.crl_uris)
                .concat(pk.ocsp_uris);
              /* filter for URLS included in the report */
              xuris = xuris.filter(function(value) {
                return self.included_uris.includes(value);
              });
              uris += xuris;
            }
          }
          if (uris.length > 0) {
            let data = this.results.filter(function(value) {
              return (
                uris.includes(value.uri_id) ||
                uris.includes(value.base_ocsp_uri)
              );
            });

            let issuer_uptime = {};
            let uri_data = {};
            for (const p in data) {
              let period = data[p];
              let period_up = moment.duration(period.uptime).asMinutes();
              let period_down = moment.duration(period.downtime).asMinutes();
              if (moment.utc(period.time).format("LL") in issuer_uptime) {
                issuer_uptime[
                  moment.utc(period.time).format("LL")
                ].up += period_up;
                issuer_uptime[
                  moment.utc(period.time).format("LL")
                ].down += period_down;
              } else {
                issuer_uptime[moment.utc(period.time).format("LL")] = {
                  up: period_up,
                  down: period_down
                };
              }
              if (!(period.uri_id in uri_data)) {
                uri_data[period.uri_id] = {
                  info: this.uri_dict[period.uri_id],
                  data: {}
                };
              }
              if (
                moment.utc(period.time).format("LL") in uri_data[period.uri_id]
              ) {
                uri_data[period.uri_id].data[
                  moment.utc(period.time).format("LL")
                ].up += period_up;
                uri_data[period.uri_id].data[
                  moment.utc(period.time).format("LL")
                ].down += period_down;
                let uptime = Number.parseFloat(
                  100 *
                    (uri_data[period.uri_id].data[
                      moment.utc(period.time).format("LL")
                    ].up /
                      (uri_data[period.uri_id].data[
                        moment.utc(period.time).format("LL")
                      ].up +
                        uri_data[period.uri_id].data[
                          moment.utc(period.time).format("LL")
                        ].down))
                ).toPrecision(3);
                uri_data[period.uri_id].data[
                  moment.utc(period.time).format("LL")
                ].percent = uptime;
              } else {
                let uptime = Number.parseFloat(
                  100 * (period_up / (period_up + period_down))
                ).toPrecision(3);
                uri_data[period.uri_id].data[
                  moment.utc(period.time).format("LL")
                ] = {
                  up: period_up,
                  down: period_down,
                  sensors: [],
                  percent: uptime
                };
              }

              uri_data[period.uri_id].data[
                moment.utc(period.time).format("LL")
              ].sensors.push({
                sensor_id: period.sensor_id,
                uptime: period.uptime,
                downtime: period.downtime
              });

              //issuer_uptime[moment.utc(period.time).format('LL')] = Number.parseFloat(100*(period_up / (period_up+period_down))).toPrecision(3);
              periods.add(moment.utc(period.time).format("LL"));
            }
            let uptime = this.sum_durations(data, "uptime"),
              downtime = this.sum_durations(data, "downtime");
            for (const x in issuer_uptime) {
              issuer_uptime[x] = Number.parseFloat(
                100 *
                  (issuer_uptime[x].up /
                    (issuer_uptime[x].up + issuer_uptime[x].down))
              ).toPrecision(3);
            }
            if (issuer.name) {
              result.push({
                canonical_sha1: issuer.name.canonical_sha1,
                name: issuer.name,
                uris: uris,
                uptime: uptime,
                downtime: downtime,
                percent: (uptime / (uptime + downtime)) * 100,
                total_time: uptime + downtime,
                data: issuer_uptime,
                uri_data: uri_data
              });
            }
          }
        }
        self.time_periods = periods; // All time periods seen in this report
        return result;
      } else {
        return [];
      }
    },
    time_periods_headers: function() {
      let headers = [{ text: "Issuer", value: "name.friendly_name" }];

      if (this.time_periods) {
        Array.from(this.time_periods).forEach(function(value) {
          let test = "data." + value;
          headers.push({ text: value, value: test });
        });
      }
      return headers;
    },
    group_downtime: function() {
      if (this.results && this.monitored_groups) {
        let result = [];
        for (const x in this.group_uris) {
          let include_this_group = false;
          let group = this.group_uris[x];
          let uris = [];
          for (const z in group.rules) {
            let rule = group.rules[z];
            let include_ca =
              group.group_type === 1 &&
              this.filters.filter_group_rules.ca.includes(rule.id);
            if (include_ca) {
              if (this.filters.include.aia) {
                uris = uris.concat(rule.uris.aia_uris);
              }
              if (this.filters.include.sia) {
                uris = uris.concat(rule.uris.sia_uris);
              }
              if (this.filters.include.crl) {
                uris = uris.concat(rule.uris.crl_uris);
              }
              if (this.filters.include.ocsp) {
                uris = uris.concat(rule.uris.ocsp_uris);
              }
            }
            let include_path =
              group.group_type !== 1 &&
              this.filters.filter_group_rules.path.includes(rule.id);
            if (include_path) {
              if (this.filters.include.aia) {
                uris = uris.concat(rule.uris.aia_uris);
              }
              if (this.filters.include.sia) {
                uris = uris.concat(rule.uris.sia_uris);
              }
              if (this.filters.include.crl) {
                uris = uris.concat(rule.uris.crl_uris);
              }
              if (this.filters.include.ocsp) {
                uris = uris.concat(rule.uris.ocsp_uris);
              }
            }
            if (include_ca || include_path) {
              include_this_group = true;
            }
          }
          if (include_this_group) {
            let data = this.results.filter(function(value) {
              return (
                uris.includes(value.uri_id) ||
                uris.includes(value.base_ocsp_uri)
              );
            });

            let period_uptime = {};
            for (const p in data) {
              let period = data[p];
              let period_up = moment.duration(period.uptime).asMinutes();
              let period_down = moment.duration(period.downtime).asMinutes();
              if (moment.utc(period.time).format("LL") in period_uptime) {
                period_uptime[
                  moment.utc(period.time).format("LL")
                ].up += period_up;
                period_uptime[
                  moment.utc(period.time).format("LL")
                ].down += period_down;
              } else {
                period_uptime[moment.utc(period.time).format("LL")] = {
                  up: period_up,
                  down: period_down
                };
              }
            }

            let uptime = this.sum_durations(data, "uptime"),
              downtime = this.sum_durations(data, "downtime");
            for (const x in period_uptime) {
              period_uptime[x] = Number.parseFloat(
                100 *
                  (period_uptime[x].up /
                    (period_uptime[x].up + period_uptime[x].down))
              );
            }
            result.push({
              id: group.id,
              name: group.name,
              color: group.color,
              uris: uris,
              uptime: uptime,
              downtime: downtime,
              percent: uptime / (uptime + downtime),
              total_time: uptime + downtime,
              data: period_uptime
            });
          }
        }
        return result;
      } else {
        return [];
      }
    },
    included_uris: function() {
      let result = [];
      for (const x in this.group_downtime.map(e => e.uris)) {
        let group = this.group_downtime.map(e => e.uris)[x];
        result = result.concat(group);
      }
      return result;
    }
  },
  created: function() {
    UserService.getLocalApi("uris/?monitored=True&size=10000").then(
      response => {
        this.uris = response.data.results;
      }
    );
  },
  mounted: function() {},
  methods: {
    get_data: function(x, y) {
      return y;
    },
    get_classes: function(value) {
      let color = this.get_class(value);
      return {
        "ma-0": true,
        [color]: true,
        "pa-0": true,
        "skittle-input": true
      };
    },
    get_class: function(actual) {
      if (actual == null) {
        return "c-0-color";
      }
      var id = 0;
      var arrayLength = this.values.length;
      for (var i = 0; i < arrayLength; i++) {
        if (parseFloat(actual) >= parseFloat(this.values[i])) {
          id++;
        }
      }
      if (id === 0) {
        return "c-1-color";
      } else if (id === 1) {
        return "c-2-color";
      } else if (id === 2) {
        return "c-3-color";
      } else if (id === 3) {
        return "c-4-color";
      } else if (id === 4) {
        return "c-5-color";
      } else {
        return "c-6-color";
      }
    },
    update_skittles: function() {},
    filter_group_rules: function(settings) {
      console.log(settings);
      this.filters.filter_group_rules = settings;
    },
    sum_durations: function(items, prop) {
      return items.reduce(function(a, b) {
        return a + moment.duration(b[prop]).asMinutes();
      }, 0);
    },
    do_search: function() {
      let self = this;
      self.public_loading = true;
      self.private_loading = true;
      self.loading = true;
      let query_params = [
        "interval=" + this.report_settings.interval,
        "tz_offset=" + this.report_settings.timezone.value,
        "start=" + this.report_settings.dates[0],
        "end=" + this.report_settings.dates[1]
      ];

      UserService.getLocalApi(
        "availability/?monitored=true&" + query_params.join("&")
      ).then(response => {
        self.private_results = response.data;
        self.private_loading = false;
      });

      //self.public_loading = false;
      //self.loading = false;

      let remote_ids = self.uris.map(e => e.public_id).filter(Number);
      var formData = new FormData();
      formData.append("ids", remote_ids);
      UserService.postRemoteApi(
        "availability/get_by_ids/?" + query_params.join("&"),
        formData
      ).then(response => {
        // public results must map sha1 to local url ID
        self.public_results = response.data.map(e => ({
          ...e,
          uri_id: this.uri_ids[e.sha1]
        }));
        self.public_loading = false;
        self.loading = false;
      });
    }
  }
};
</script>

<style>
.skittle-input,
.skittle-input input {
  text-align: center;
}
.c-1-color,
.c-1-color input {
  background: #d73027;
  color: white !important;
}
.c-2-color,
.c-2-color input {
  background: #fc8d59;
}
.c-3-color,
.c-3-color input {
  background: #fee08b;
}
.c-4-color,
.c-4-color input {
  background: #ffffbf !important;
}
.c-5-color,
.c-5-color input {
  background: #d9ef8b !important;
}
.c-6-color,
.c-6-color input {
  background: #91cf60 !important;
}
</style>
