<template>
  <v-card flat>
    <div v-for="group in group_downtime" style="float:left;" :key="group.id">
      <v-progress-circular
        :size="100"
        :width="20"
        :value="100 * group.percent"
        :color="group.color"
        class="ma-2"
      >
        <span> {{ (group.percent * 100) | round(1) }}</span>
      </v-progress-circular>
    </div>
    <br clear="both" />
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="group_downtime">
      <template v-slot:item.name="{ item }">
        <div
          :style="`border-left:22px solid ${item.color}`"
          class="pa-0 ma-0 pl-2"
        >
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.downtime="{ item }">
        <span v-if="item.uptime > 0">
          {{ item.downtime | round(0) }} minutes (out of
          {{ item.total_time | round(0) }})
        </span>
        <span v-else>
          No URLs
        </span>
      </template>
      <template v-slot:item.percent="{ item }">
        {{ (item.percent * 100) | round(3) }}%
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "GroupAvailabilityPies",
  props: ["group_downtime"],

  filters: {
    round: function(number, digits) {
      return Math.round(number, digits);
    }
  },
  data: function() {
    return {
      test: [],
      saving: false,
      saved: false,
      error: false,
      headers: [
        {
          text: "Group",
          sortable: true,
          value: "name",
          export: true
        },
        {
          text: "Aggregate Downtime",
          align: "left",
          sortable: true,
          value: "downtime",
          export: true
        },
        {
          text: "Uptime (%)",
          align: "left",
          sortable: true,
          value: "percent",
          export: true
        }
      ]
    };
  }
};
</script>

<style scoped></style>
