<template>
  <v-card flat>
    <v-data-table :headers="headers" :items="uri_downtime">
      <template v-slot:item.uri="{ item }">
        <UriNoAvailability :url="item.uri"></UriNoAvailability>
      </template>
      <template v-slot:item.downtime="{ item }">
        <span v-if="item.total_time > 0">
          {{ item.downtime | round(0) }} minutes
        </span>
        <span v-else>
          No Data
        </span>
      </template>
      <template v-slot:item.percent="{ item }">
        {{ (item.percent * 100) | round(3) }}%
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import UriNoAvailability from "../../uris/NoAvailability";
export default {
  name: "ReportingAvailabilityUriTable",
  components: { UriNoAvailability },
  props: ["data", "uris", "included_uris"],
  data: function() {
    return {
      test: [],
      saving: false,
      saved: false,
      error: false,
      headers: [
        {
          text: "URI",
          align: "left",
          sortable: true,
          value: "uri",
          export: true
        },
        {
          text: "Aggregate Downtime",
          align: "left",
          sortable: true,
          value: "downtime",
          export: true
        },
        {
          text: "Uptime (%)",
          align: "left",
          sortable: true,
          value: "percent",
          export: true
        }
      ]
    };
  },
  filters: {
    duration: function(str) {
      if (str) {
        return moment.duration(str).minutes();
      } else {
        return "None";
      }
    },
    round: function(number, digits) {
      return Math.round(number, digits);
    }
  },
  computed: {
    uri_downtime: function() {
      if (this.data.length > 0 && this.included_uris.length > 0) {
        let result = [];
        for (const x in this.uris) {
          let uri = this.uris[x];
          if (this.included_uris.includes(uri.id)) {
            let data = this.data.filter(function(value) {
              return value.uri_id === uri.id;
            });

            let uptime = this.sum_durations(data, "uptime"),
              downtime = this.sum_durations(data, "downtime");
            result.push({
              uri: { id: uri.id, uri: uri.uri },
              uptime: uptime,
              downtime: downtime,
              percent: uptime / (uptime + downtime),
              total_time: uptime + downtime
            });
          }
        }
        return result;
      } else {
        return false;
      }
    }
  },
  methods: {
    sum_durations: function(items, prop) {
      return items.reduce(function(a, b) {
        if (b[prop]) {
          return a + moment.duration(b[prop]).asMinutes();
        } else {
          return a;
        }
      }, 0);
    }
  }
};
</script>

<style scoped></style>
