<template>
  <v-card class="mb-2 pa-2" style="">
    <v-card-title>
      Group Availability
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="mode" mandatory class="mt-n5 ml-3">
        <v-btn @click="mode = 0">
          <v-icon>mdi-chart-bar</v-icon>
        </v-btn>

        <v-btn @click="mode = 1">
          <v-icon>mdi-chart-areaspline-variant</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text class="pa-0" v-if="data.series.length">
      <chartist
        ratio="ct-major-twelfth"
        :data="data"
        :type="type"
        style="min-height:250px;"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ReportingAvailabilityBarChart",
  props: ["settings", "headers"],
  data: function() {
    return {
      saving: false,
      saved: false,
      error: false,
      editing: false,
      unsaved: null,
      certs: null,
      certs_loading: true,
      num_certs: null,
      already: [],
      seven: [],
      thirty: [],
      year: [],
      over: [],
      high: 100,
      low: 100,
      mode: 0,
      data: { series: [], labels: [] },
      type: "Bar"
    };
  },
  computed: {
    display_dates: function() {
      let result = this.headers.map(e => e.text);
      result.shift();
      return result;
    },
    display_data: function() {
      let result = [];
      for (const x in this.settings) {
        let data = this.settings[x].data;
        let dres = [];
        for (const z in data) {
          dres.push(data[z]);
          if (data[z] < this.low) {
            //this.low = data[z];
          }
        }
        result.push(dres);
      }
      console.log(result);
      return result;
    }
  },
  watch: {
    display_data: function() {
      this.update_chart();
    },
    mode: function() {
      this.update_chart();
    }
  },
  methods: {
    update_chart: function() {
      if (this.mode === 0) {
        this.data = {
          labels: this.display_dates,
          series: this.display_data
        };
      } else {
        this.data = {
          labels: this.display_dates,
          series: this.display_data
        };
      }
    }
  },
  mounted: function() {
    this.update_chart();
  }
};
</script>

<style scoped></style>
