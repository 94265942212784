<template>
  <v-text-field
    readonly
    dense
    style="height:24px;font-size:12px;width:100%;"
    ref="textToCopy"
    class="ma-0 pa-0 flex-grow-1"
    :value="url.uri"
  >
    <template v-slot:append class="ma-0 pa-0">
      <v-btn
        class="ma-0 mr-0 pa-2 ml-1 mb-1"
        color="primary"
        label
        small
        text
        outlined
        @click="copyText"
      >
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>

      <v-btn
        class="ma-0 mr-0 pa-2 ml-1 mb-1"
        color="primary"
        label
        small
        text
        outlined
        :href="`/url/${url.id}/`"
      >
        <v-icon small>mdi-eye</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "UriNoAvailability",
  props: ["url"],
  data: function() {
    return {
      remote: false,
      loading: false
    };
  },
  computed: {},
  mounted: function() {},
  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    }
  }
};
</script>

<style scoped></style>
